<template>
  <div class="wrap">
    <van-nav-bar :title="$t(`user.tools.${type}`)">
      <div class="nav_left_wrap" slot="left">
        <van-icon name="arrow-left" color="#fff" @click="$router.back()" />
      </div>
    </van-nav-bar>
    <van-tabs :ellipsis="false">
      <van-tab v-for="(item, index) in tabs" :key="index" :title="item.title">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#0184f6">
          <van-swipe-item
            v-for="(c_item, c_index) in item.swipers"
            :key="`${index}${c_index}`"
          >
            <div v-html="c_item.content" style="padding: 15px"></div>
          </van-swipe-item>
        </van-swipe>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { NavBar, Icon, Tab, Tabs, Swipe, SwipeItem, Toast } from "vant";
import { $get } from "@/utils/request";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  props: {
    type: ""
  },
  data() {
    // this.tabs = [
    //   {
    //     title: "Buy USDT",
    //     swipers: [
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>1. Log in to Huobi app.</p><p style="text-indent: -1.2rem;padding-left: 1.5rem;">2. Find entry in the "Balances" menu. </p></div><img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.bltangpin.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/1/01_01.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;">
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>3. After entering the Balances menu, you can see the Exchange /Futures/fiat /Margin/mining pool.</p>
    //         <p style="text-indent: -1.2rem;padding-left: 1.5rem;">4. Select the "Fiat" entry, find "USDT" below, and click to enter the fiat currency transaction.</p></div>
    //         <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.bltangpin.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/1/01_02.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;">
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>5. After entering the fiat trading interface you will see that there are digital currencies such as BTC/USDT/ETH along the top. Select USDT, and choose the amount or quantity of USDT to buy.</p></div>
    //         <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.bltangpin.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/1/01_03.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;">
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>6. The minimum purchase amount for USDT is 20. After clicking "Buy with 0 handling fee", the bank card/Alipay/WeChat payment methods will pop up.</p>
    //         <p style="text-indent: -1.2rem;padding-left: 1.5rem;">7. After selecting the payment method, only after the payment has been successfully made can you click "I've paid". If you have not yet paid but click "I've paid", your Huobi account will be considered to have breached regulations and the account will be frozen.</p>
    //         <p style="text-indent: -1.2rem;padding-left: 1.5rem;">8. After the payment succeeds, wait for the holder to transfer USDT to you, which will be completed within 5 minutes; or you can take the initiative to contact the counterparty through the interface.</p>
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>9. The USDT received will be deposited into the "Exchange" account.</p>
    //         </div>`,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Sell USDT",
    //     swipers: [
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>Selling process (selling USDT can only be done in a fiat currency account)</p>
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>1. Find the entry in the "Balances" menu.</p>
    //         </div><img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.bltangpin.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/2/02_01.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;">
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>2. Enter the assets, hit the fiat currency entry, click USDT, find the "Transfer" button below, and click to enter.</p></div>
    //         <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.bltangpin.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/2/02_02.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;">
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>3. Enter the conversion interface and choose to switch from "Exchange" to "Fiat account"; select "USDT" for the currency in the middle, select the amount of USDT you want to transfer, and click the transfer amount below.</p></div>
    //         <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.bltangpin.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/2/02_03.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;">
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>4. After the transfer is successful, the prompt "The funds have been successfully transferred to the fiat currency account, and the fiat currency transaction will be carried out immediately" will appear; click "Fiat transaction".</p>
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>5. Select the "Fiat" entry; find the "USDT" below and click to enter, then find “Fiat transaction” to enter.</p>
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>6. Choose "I want to sell" above, find USDT, and choose the amount or quantity of USDT to sell.</p>
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>7. The minimum sale quantity is 20 USDT. After entering the quantity, click "Sell with 0 fees".</p>
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>8. After confirming the sale, wait for the buyer to take over. Generally, someone will take over within 5 minutes. If someone has already transmitted the corresponding USDT amount to your account, you can choose "Release", and the sale transaction will end after the release.</p>
    //         </div>`,
    //       },
    //     ],
    //   },
    //   {
    //     title: "USDT top up",
    //     swipers: [
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>1. Find the entry in the "Balances" menu.</p></div><img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.bltangpin.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/3/03_01.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;">
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>2. After entering the Balances menu, find the "Exchange" entry in the interface, and click "USDT".</p></div>
    //         <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.bltangpin.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/3/03_02.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;">
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>3. After entering USDT, click the "Deposit" button.</p></div>
    //         <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.bltangpin.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/3/03_03.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;">
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>4. After entering the top up interface, select "ERC20" for the blockchain name, and the QR code and top up address will be displayed below.</p>
    //         </div>
    //         <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.bltangpin.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/3/03_04.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;">
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>5. Select the "Fiat" entry, find "USDT" below and click to enter, and then find fiat currency transaction and enter.</p>
    //         <p style='padding-left: 1.5rem;font-size: 12px;color: #ff0000;'>For top ups and withdrawals, the blockchain name for transactions between different platforms must be consistently chosen as "ERC20"; otherwise the transaction will be unsuccessful and the USDT will be unable to be retrieved!</p>
    //         </div>
    //         <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.bltangpin.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/3/03_05.jpg">`,
    //       },
    //     ],
    //   },
    //   {
    //     title: "USDT withdrawal",
    //     swipers: [
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;">
    //         <p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         1. Find the entry in the "Balances" menu.
    //                     </p></div> <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.fengdadzjx.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/4/04_01.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         2. After entering the asset menu, find the "Exchange" entry in the interface, and click "USDT".
    //                     </p></div> <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.sdzhishangwuye.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/4/04_02.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         3. After entering USDT, click the "Withdraw" button.
    //                     </p></div> <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.sdzhishangwuye.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/4/04_03.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         4. After entering the withdrawal interface, select "ERC20" for the blockchain name, and enter below the address for the withdrawal.
    //                     </p><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         5. "Withdraw" is to transfer USDT in Huobi account to other platform(s); the chain name on the other platform(s) must also be "ERC20".
    //                     </p></div>`,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Withdrawal from Huobi to wallet",
    //     swipers: [
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         1. Find the entry in the "Balances" menu.
    //                     </p></div> <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.sdzhishangwuye.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/5/05_01.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         2. Find the "Exchange" entry in the interface and click "USDT".
    //                     </p></div> <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.sdzhishangwuye.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/5/05_02.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         3. After entering USDT, click the "Withdraw" button.
    //                     </p></div> <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.sdzhishangwuye.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/5/05_03.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         4. Enter the Huobi USDT withdrawal page; enter the address for the withdrawal or scan the site for USDT payment to generate a payment receipt QR code, and make a transfer to the wallet.
    //                     </p></div> <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.sdzhishangwuye.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/5/05_04.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         5. There are two ways to top up on the site: USDT payment and USDT transfer.
    //                     </p><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         A. For a USDT payment, enter the corresponding amount of USDT in the interface, then click "Top up now"; the USDT payment address and payment receipt QR code will appear.
    //                     </p><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         B. For a USDT transfer, if there is no corresponding receiving address and QR code for the USDT transfer on the site, you can click "Add wallet".
    //                     </p></div>`,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Withdrawal from wallet to Huobi",
    //     swipers: [
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         1. If the blockchain address for USDT withdrawal is not bound on the site, it must be bound first. The name of the bound blockchain name must be an ERC20 name; enter the ERC20 address for the Huobi USDT top up in the USDT blockchain address.
    //                     </p></div> <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.sdzhishangwuye.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/6/06_01.jpg">`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         1. If the blockchain address for USDT withdrawal is not bound on the site, it must be bound first. The name of the bound blockchain name must be an ERC20 name; enter the ERC20 address for the Huobi USDT top up in the USDT blockchain address.
    //                     </p></div> <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.sdzhishangwuye.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/6/06_02.jpg"`,
    //       },
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         2. After the withdraw on the site, meet the site withdrawal conditions and submit the application; the platform will then arrange the transfer to the bound blockchain address.
    //                     </p></div> <img style="width: 60%;margin: auto;display:block" src="https://ltcp-ali.sdzhishangwuye.com/dist-mix/i18zb-h5/image-i18zb-h5/en/usdt/6/06_03.jpg">`,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Download Huobi installation package",
    //     swipers: [
    //       {
    //         content: `<div style="margin:0 auto 1rem;line-height: 22px;"><p style='text-indent: -1.2rem;padding-left: 1.5rem;font-weight: 700'>
    //                         Huobi website
    //                     </p><p style='text-indent: -1.2rem;padding-left: 1.5rem;'>
    //                         1. Website: www.huobi.com (download a Huobi installation package for your terminal).
    //                     </p><a href="https://www.huobi.com/" style="position: relative;top: 0;width: 100%;display:flex;justify-content: center"><p style='text-indent: 0;padding-left: 1.5rem;background: #0184f6;color: var(--textColor);text-align: center;border-radius: 0.35rem;padding: 0.5rem 2rem;margin: 25% auto auto;display: inline-block;'>
    //                         Go to Huobi official website
    //                     </p></a></div>`,
    //       },
    //     ],
    //   },
    // ];
    return { tabs: [] };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        Toast.loading();
        const res = await $get("/third/js/read", { file: this.type });
        Toast.clear();
        const { ret, data, msg } = res.data;
        if (ret === 1) {
          this.tabs = eval(`(${data})`);
          return;
        }
        Toast(msg);
      } catch (error) {
        Toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  height: 100vh;
  background: #fff;
  /deep/ .van-nav-bar {
    background-color: #ff0000;
  }
  /deep/ .van-nav-bar__title {
    color: var(--textColor);
  }
  .content {
    display: flex;
    align-items: center;
    @border: 1px solid #ccc;
    border-bottom: @border;
    width: 100vw;
    > div {
      width: 50%;
      line-height: calc(32rem / 16);
      text-indent: calc(24rem / 16);
    }
    .left {
      font-size: calc(14rem / 16);
      border-right: @border;
    }
    .right {
      font-size: calc(16rem / 16);
    }
  }
  .my-swipe {
    /deep/ .van-swipe__track {
      width: 100vw !important;
    }
    /deep/ .van-swipe__indicator {
      background: #666;
    }
    /deep/ .van-swipe__indicators {
      bottom: 0px;
    }
  }
}
</style>